<template>
    
    <div class="bloc-layout">

        <bloc-data-table
            :title="title"
            :columns="columns"
            :data="data"
            :loading="loading"
        >
            <template v-slot:title>
                <v-btn color="primary" @click="handleAdd">
                    <span style="color: #fff;">
                        {{ $vuetify.lang.t('$vuetify.userManage.bloc.insertBtn') }}
                    </span>
                </v-btn>
            </template>
            <template v-slot:action="action">
                <v-row justify="start">

                    <v-icon color="primary" small @click="handleEidt(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleEidt(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.userManage.editBtn') }}
                    </span>

                    <div class="mx-1"></div>

                    <v-icon color="primary" small @click="handleDelete(action.value.item)">delete</v-icon>
                    <span class="table-btn-span" @click="handleDelete(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.userManage.deleteBtn') }}
                    </span>

                </v-row>
            </template>
        </bloc-data-table>

        <add-bloc ref="add" @handleSearch="handleSearch"/>
        <edit-bloc ref="edit" @handleSearch="handleSearch"/>
        <del-bloc ref="del" @handleSearch="handleSearch"/>
    
    </div>
</template>

<script>
import * as api from '../../api'
import BlocDataTable from './components/BlocDataTable.vue'
import AddBloc  from './components/AddBloc.vue'
import EditBloc from './components/EditBloc.vue'
import DelBloc  from './components/DelBloc.vue'

export default {

    components: {
        BlocDataTable,
        AddBloc,
        EditBloc,
        DelBloc
    },

    data () {
      return {

            title: this.$vuetify.lang.t('$vuetify.userManage.bloc.title'),

            loading: true,

            columns: [
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.bloc.table.name'),
                    value: 'name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.bloc.table.contact'),
                    value: 'contact'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.bloc.table.domain'),
                    value: 'domain'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.radacctRule.title'),
                    sortable: false,
                    value: 'rulename'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.bloc.table.action'),
                    sortable: false,
                    value: 'action'
                },
            ],

            data: [
                // {
                //     id: 1,
                //     name: '霏云',
                //     contact: 'xx路xx号，联系电话：1234567210',
                //     domain: 'pheicloud.com'
                // }
            ]
      }
    },

    created () {
      this.initialize()
    },

    methods: {

        initialize() {
            this.getData();
        },

        getData () {
            this.loading = true;
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.data = resp.data;
            }).finally(resp => {
                this.loading = false;
            })
        },

        handleAdd () {
            this.$refs.add.handleOpen();
        },

        handleEidt (item) {
            this.$refs.edit.handleOpen(item);
        },

        handleDelete (item) {
            this.$refs.del.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        }
    }
}
</script>

<style scoped>

.bloc-layout {
    margin: 0 10px;
    min-height: 90%;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}
</style>