<template> 
    <div class="main_lay">
        <el-dialog title="Mwan Top" :visible.sync="dialogShowMwan" :before-close="handleclose" width="95%">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <el-form
                        :inline="true" 
                        ref="form" size="small">
                    <el-form-item label="4g1状态">
                        <el-select v-model="form.sim1" placeholder="请选择" clearable style="width: 100px;">
                            <el-option
                            v-for="item in statuslist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="4g2状态">
                        <el-select v-model="form.sim2" placeholder="请选择" clearable style="width: 100px;">
                            <el-option
                            v-for="item in statuslist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="时间范围">
                        <el-date-picker
                        @change="list(true)"
                        v-model="valuemonth"
                        type="month"
                        placeholder="选择月"
                        value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item> -->
                    <el-form-item >
                        <el-button type="primary" size="small" style="float: right;" @click="list(true)">搜索</el-button>
                    </el-form-item>
                    <el-form-item >
                        <el-button type="primary" size="small" style="float: right;" @click="handExport()">导出</el-button>
                    </el-form-item>
                    </el-form>
                </div>
                <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                max-height="500">
                    <el-table-column
                    fixed
                    prop="shop_name"
                    label="场所名称"
                    width="150">
                    </el-table-column>
                    <el-table-column
                    fixed
                    prop="device_name"
                    label="设备名称"
                    width="150">
                    </el-table-column>
                    <el-table-column
                    fixed
                    label="设备状态"
                    width="80">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="scope.row.heartbeat" placement="top">
                                <el-tag type="success" v-if="scope.row.device_status=='在线'">{{scope.row.device_status}}</el-tag>
                                <el-tag type="info" v-else>{{scope.row.device_status}}</el-tag>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                    fixed
                    prop="main_net"
                    label="主路线"
                    width="120">
                    </el-table-column>
                    <el-table-column label="4g1信息">
                        <el-table-column
                        prop="sim1_onlin_status"
                        label="4g1-状态">
                        </el-table-column>
                        <el-table-column
                        prop="sim1_provider"
                        label="4g1-运营商">
                        </el-table-column>
                        <el-table-column
                        sortable
                        prop="sim1_tx_bytes_str"
                        label="4g1-上行流量">
                        </el-table-column>
                        <el-table-column
                        sortable
                        prop="sim1_rx_bytes_str"
                        label="4g1-下行流量">
                        </el-table-column>
                        <el-table-column
                        sortable
                        prop="sim1_tx_bytes_rate"
                        label="4g1-上行速度">
                        </el-table-column>
                        <el-table-column
                        sortable
                        prop="sim1_rx_bytes_rate"
                        label="4g1-下行速度">
                        </el-table-column>
                        <el-table-column
                        sortable
                        label="4g1-信号强度">
                            <template slot-scope="scope">
                                -{{scope.row.sim1_rssi}}dBm
                            </template>
                        </el-table-column>
                        <el-table-column
                        sortable
                        label="4g1-丢包率">
                            <template slot-scope="scope">
                                {{scope.row.sim1_packet_loss}}%
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="4g2信息">
                        <el-table-column
                        prop="sim2_onlin_status"
                        label="4g2-状态">
                        </el-table-column>
                        <el-table-column
                        prop="sim2_provider"
                        label="4g2-运营商">
                        </el-table-column>
                        <el-table-column
                        sortable
                        prop="sim2_tx_bytes_str"
                        label="4g2-上行流量">
                        </el-table-column>
                        <el-table-column
                        sortable
                        prop="sim2_rx_bytes_str"
                        label="4g2-下行流量">
                        </el-table-column>
                        <el-table-column
                        sortable
                        prop="sim2_tx_bytes_rate"
                        label="4g2-上行速度">
                        </el-table-column>
                        <el-table-column
                        sortable
                        prop="sim2_rx_bytes_rate"
                        label="4g2-下行速度">
                        </el-table-column>
                        <el-table-column
                        sortable
                        label="4g2-信号强度">
                            <template slot-scope="scope">
                                -{{scope.row.sim2_rssi}}dBm
                            </template>
                        </el-table-column>
                        <el-table-column
                        sortable
                        label="4g2-丢包率">
                            <template slot-scope="scope">
                                {{scope.row.sim2_packet_loss}}%
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
import * as api from './../../../api'
import exportExcel from '../../../utils/export'

export default {
    data () {
        return {
            dialogShowMwan:false,
            tableData:[],
            valuemonth:"",
            form:{
                start_time:"",
                stop_time:"",
                sim1:"",
                sim2:"",
            },
            statuslist:[
                {
                    value: "1",
                    label: '在线'
                }, {
                    value: "2",
                    label: '离线'
                }
            ],
        }
    },
    methods: {
        open(){
            this.dialogShowMwan=true
            var year = new Date().getFullYear()
            var mon = new Date().getMonth()+1
            this.valuemonth = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + "01";
            
            this.list(true)
        },
        list(val){
            const date = new Date(this.valuemonth.slice(0, 4), parseInt(this.valuemonth.slice(5, 7)), 1);
            
            this.form.start_time = this.valuemonth
            this.form.stop_time = date.toISOString().slice(0, 10)

            let that = this
            this.$http.post(api.default.gateway.alldevicelist,this.form,{emulateJSON:true}).then(res => {
                if(res.errno==0){
                    if(res.data == null){
                        that.tableData = []
                    }else{
                        that.tableData = res.data
                    }   
                }
            })
        },
        handleclose(){
            this.dialogShowMwan=false
        },
        handExport(){
            const titleArr = [
                "场所名称",
                "设备名称",
                "设备状态",
                "主路线",
                "4g1-状态",
                "4g1-运营商",
                "4g1-上行流量",
                "4g1-下行流量",
                "4g1-上行速度",
                "4g1-下行速度",
                "4g1-信号强度",
                "4g1-丢包率",
                "4g2-状态",
                "4g2-运营商",
                "4g2-上行流量",
                "4g2-下行流量",
                "4g2-上行速度",
                "4g2-下行速度",
                "4g2-信号强度",
                "4g2-丢包率",
            ]
            const exportdata = []
            for (let i of this.tableData) {
                let tempdata = {
                    "shop_name" : i.shop_name,
                    "device_name" : i.device_name,
                    "device_status" : i.device_status,
                    "main_net" : i.main_net,
                    "sim1_onlin_status" : i.sim1_onlin_status,
                    "sim1_provider" : i.sim1_provider,
                    "sim1_tx_bytes_str" : i.sim1_tx_bytes_str,
                    "sim1_rx_bytes_str" : i.sim1_rx_bytes_str,
                    "sim1_tx_bytes_rate" : i.sim1_tx_bytes_rate,
                    "sim1_rx_bytes_rate" : i.sim1_rx_bytes_rate,
                    "sim1_rssi" : "-"+i.sim1_rssi+"dBm",
                    "sim1_packet_loss" : i.sim1_packet_loss,
                    "sim2_onlin_status" : i.sim2_onlin_status,
                    "sim2_provider" : i.sim2_provider,
                    "sim2_tx_bytes_str" : i.sim2_tx_bytes_str,
                    "sim2_rx_bytes_str" : i.sim2_rx_bytes_str,
                    "sim2_tx_bytes_rate" : i.sim2_tx_bytes_rate,
                    "sim2_rx_bytes_rate" : i.sim2_rx_bytes_rate,
                    "sim2_rssi" : "-"+i.sim2_rssi+"dBm",
                    "sim2_packet_loss" : i.sim2_packet_loss,
                }
                exportdata.push(tempdata)
            }
            exportExcel(exportdata, '设备信息列表', titleArr, 'sheetName');
        }
    }
}
</script>