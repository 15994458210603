<template>
    <div id="Rule" class="rule-layout">
        <radacct-rule-search @handleAdd="handleAdd" :title="title"/>
        <radacct-rule-data-table
                class="mt-8"
                @handleSearch="handleSearch"
                :loading="loading"
                :columns="columns"
                :data   ="data"
                :total  ="total"
                :search ="search">
              <template v-slot:action="action">
                <v-row justify="start">

                    <!-- <v-icon color="primary" small @click="handleRule(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleRule(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.radacctRule.table.rule') }}
                    </span>

                    <div class="mx-1"></div> -->

                    <v-icon color="primary" small @click="handleEidt(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleEidt(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.radacctRule.btn.editBtn') }}
                    </span>

                    <div class="mx-1"></div>

                    <v-icon color="primary" small @click="handleDelete(action.value.item)">delete</v-icon>
                    <span class="table-btn-span" @click="handleDelete(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.radacctRule.btn.deleteBtn') }}
                    </span>

                </v-row>
            </template>
        </radacct-rule-data-table>
    
        <edit-rules ref="rules" @handleSearch="handleSearch"></edit-rules>
        <add-radacct-rule ref="add" @handleSearch="handleSearch"></add-radacct-rule>
        <edit-radacct-rule ref="edit" @handleSearch="handleSearch"></edit-radacct-rule>
    </div>
</template>
<script>
import * as api from '../../api'
import RadacctRuleDataTable from '@/views/Radacct/component/RadacctRuleDataTable.vue';
import RadacctRuleSearch from '@/views/Radacct/component/RadacctRuleSearch.vue'
import AddRadacctRule from '@/views/Radacct/component/AddRadacctRule.vue';
import EditRadacctRule from '@/views/Radacct/component/EditRadacctRule.vue';
import EditRules from '@/views/Radacct/component/EditRules' 

export default {
    components: { RadacctRuleSearch, RadacctRuleDataTable, EditRadacctRule, AddRadacctRule, EditRules },
    data() {
        return {
            title: this.$vuetify.lang.t('$vuetify.radacctRule.title'),

            columns: [
                {
                    text: this.$vuetify.lang.t('$vuetify.radacctRule.table.id'),
                    sortable: false,
                    value: 'id'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.radacctRule.table.name'),
                    sortable: false,
                    value: 'name'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.userManage.site.table.radaccePrice'),
                    sortable: false,
                    value: 'radacct_price'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.radacctRule.table.action'),
                    sortable: false,
                    value: 'action'
                },
            ],              //表格列名字

            data: [],           
            loading: false,
            total: 0,
            search: {
                pagesize: 8,
                pagenum: 1,
                name: null
            },
        }
    },

    mounted() {
        this.getRulesData()
        
    },

    methods: {
        handleSearch(){
            this.getRulesData()
        },

        handleAdd(){
            this.$refs.add.handleOpen();
        },
    
        handleRule(item){
            this.$refs.rules.handleOpen(item);

        },

        handleEidt(item){
            this.$refs.edit.handleOpen(item);
        },

        handleDelete(item){
            this.loading = true

            var url = api.default.radacct.radacctRuleDelete + "?id=" + item.id
            this.$http.get(url).then(resp => {
                //此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return
                 Notification({
                    type: 'success',
                    message: this.$vuetify.lang.t('$vuetify.radacctRule.success')
                });
                this.getRulesData()
            }).finally(resp => {
                this.loading = false
            })
        },

        getRulesData(){
            this.loading = true

            this.$http.post(api.default.radacct.radacctRuleList,this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return
                this.data = resp.data.list
                this.total = resp.data.count
            }).finally(resp => {
                this.loading = false
            })
        },

       
    },
}
</script>
<style lang="scss" scoped>
.rule-layout {
    margin: 0 10px;
}
.table-btn-span{
    cursor: pointer;
}
</style>