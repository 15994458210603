<template> 
    <div class="main_lay">
        <el-dialog title="Mwan List" :visible.sync="dialogShowMwan" :before-close="handleclose" width="60%">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <el-form
                    :inline="true" 
                    ref="form" size="small">
                    <el-form-item label="时间范围">
                        <el-date-picker
                        v-model="daterange"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        clearable>
                        </el-date-picker>
                    </el-form-item>
                        <el-form-item >
                            <el-button type="primary" size="small" style="float: right;" @click="list(true)">搜索</el-button>
                        </el-form-item>
                        <el-form-item >
                            <el-button type="primary" size="small" style="float: right;" @click="handExport()">导出</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%">
                <el-table-column
                    prop="shop_name"
                    label="场所名称">
                    </el-table-column>
                    <el-table-column
                    prop="device_name"
                    label="设备名称">
                    </el-table-column>
                    <el-table-column
                    prop="infname"
                    label="网口名称">
                    </el-table-column>
                    <el-table-column
                    label="状态"
                    :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{showstatus(scope.row)}}
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="created_at"
                    label="时间">
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="text-align:center;margin:10px 0;"
                    @size-change="list(true)" 
                    @current-change="list(false)" 
                    :current-page.sync="currentPage" 
                    :page-size.sync="pageSize" 
                    layout="total, sizes, prev, pager, next, jumper" 
                    :total="localtotal">
                </el-pagination>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
import * as api from '../../../api'
import exportExcel from '../../../utils/export'

export default {
    data () {
        return {
            dialogShowMwan:false,
            tableData:[],
            localtotal:0,
            // 当前页
            currentPage: 1,
            // 每页多少条
            pageSize: 10,
            daterange:[],
            form:{
                pagesize:10,
                pagenum:1,
                start_time:"",
                stop_time:"",
            },
        }
    },
    methods: {
        open(){
            this.dialogShowMwan=true
            this.list(true)
        },
        list(val){
            if(val){
                this.currentPage=1
            }
            this.form.pagenum=this.currentPage
            this.form.pagesize=this.pageSize
            if (this.daterange==null){
                this.form.start_time = ""
                this.form.stop_time = ""
            }else{
                this.form.start_time = this.daterange[0]
                this.form.stop_time = this.daterange[1]
            }

            let that = this
            this.$http.post(api.default.gateway.mwan3list,this.form,{emulateJSON:true}).then(res => {
                if(res.errno==0){
                    that.localtotal = res.data.total
                    that.tableData = res.data.list
                }
            })
        },
        handExport(){
            var form = {
                start_time: "",
                stop_time: "",
            }
            if (this.daterange!=null){
                form.start_time = this.daterange[0]
                form.stop_time = this.daterange[1]
            }
            let that = this
            this.$http.post(api.default.gateway.mwan3list,form,{emulateJSON:true}).then(res => {
                if(res.errno==0){
                    const titleArr = [
                        "场所名称",
                        "设备名称",
                        "网口名称",
                        "状态",
                        "时间",
                    ]
                    const exportdata = []
                    for (let i of res.data.list) {
                        let tempdata = {
                            "shop_name" : i.shop_name,
                            "device_name" : i.device_name,
                            "infname" : i.infname,
                            "status" : that.showstatus(i),
                            "created_at" : i.created_at,
                        }
                        exportdata.push(tempdata)
                    }
                    exportExcel(exportdata, '主线路切换记录', titleArr, 'sheetName');
                }
            })
        },
        showstatus(row){
            switch (row.status) {
                case 1:
                    return "上线"

                case 2:
                    return "离线"
                
                case 3:
                    return "未启用"

                default:
                    break;
            }
        },
        handleclose(){
            this.dialogShowMwan=false
        },
    }
}
</script>