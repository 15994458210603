<template>
    <div>
        <v-card>
            <v-row>
                <v-col cols="12">
                    <v-toolbar :flat="true">
                        <v-toolbar-title>
                            <span>{{ $vuetify.lang.t('$vuetify.portalMac.selectTitle') }}</span>
                        </v-toolbar-title>

                        <v-divider class="mr-4 ml-4" inset vertical></v-divider>
                        <!-- 添加模板按钮 -->
                        <v-btn color="primary ml-3" dark @click="handleCut">
                            <span v-show="isShow">切换图表</span>
                            <span v-show="!isShow">切换详情</span>
                        </v-btn>
                        <v-btn v-show="isShow" color="primary ml-3" dark @click="exportBtn">
                            <span>导出</span>
                        </v-btn>
                        <v-btn color="primary ml-3" dark @click="handleSearch">
                            <span>
                                {{ $vuetify.lang.t('$vuetify.portalManage.searchBtn') }}
                            </span>
                        </v-btn>
                        <!-- 搜索按钮 -->
                    </v-toolbar>
                    <el-form :inline="true" ref="form" :model="form" label-width="80px">
                        <el-form-item label="查询mac">
                            <el-input v-model="formData.client_mac" clearable placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="认证域">
                            <el-select v-model="formData.name" filterable clearable placeholder="请选择">
                                <el-option v-for="item in form.group" :key="item.id" :label="item.name" :value="item.id"
                                    @clear="clearBtn">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="起止时间">
                            <el-date-picker v-model="formData.date" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </v-col>
            </v-row>
        </v-card>
        <!-- 搜索card -->
    </div>
</template>

<script>
import api from '@/api.js'
import exportFile from '@/utils/export.js'
import { Notification } from 'element-ui';

export default {
    data() {
        return {
            form: {
                group: [],
                shop: []
            },
            formData: {
                name: null,
                date: '',
                client_mac:'',
            },
            postData: {
                client_mac: '',
                currentPage: 0,
                pageSize: 0,
                gateway_id: null,
                Time_range: [],
            },
            isShow:false,
        }
    },
    props: {
        title: String,
        search: Object,
        types: Array,
        search_type: String
    },
    mounted: function () {
        this.fetchGroupList()
    },
    methods: {
        handleAdd() {
            this.$emit('handleAdd');
        },
        handleSearch() {
            this.$emit('handleSearch', this.formData);
        },
        handleCut() {
            this.isShow = !this.isShow
            this.$emit('call-parent-method');
        },
        PortalStatus(status) {
            if (status == 0) {
                return '页面加载失败'
            } else if (status == 1) {
                return '认证失败'
            } else {
                return '成功'
            }
        },
        exportBtn() {
            this.postData.Time_range = this.formData.date
            this.postData.gateway_id = this.formData.name
            if (this.postData.Time_range == "" || this.postData.Time_range == null) {
                this.$message({
                    message: '认证域和起止时间不能为空',
                    type: 'warning'
                });
                return
            }
            this.$http.post(api.authclockList, this.postData).then(resp => {
                if (resp.errno == 0) {
                    if (resp.data.list.length > 0) {
                       const titleArr = [
                        "设备品牌",
                        "认证方式",
                        "mac",
                        "状态",
                        "创建时间",
                        "更新时间",
                        ]
                        const exportdata = []
                        for (let i of resp.data.list) {
                            let tempdata = {
                                "device": i.device,
                                "authtype": i.authtype,
                                "mac": i.mac,
                                "status": this.PortalStatus(i.status),
                                "created_at": i.created_at,
                                "updated_at": i.updated_at,
                            }
                            exportdata.push(tempdata)
                        }
                        exportFile(exportdata, '统计列表', titleArr, 'sheetName'); 
                    } else {
                        Notification({
                            type: 'info',
                            message: "查询范围无数据"
                        });
                    }
                }
            }).finally(resp => {
                this.loading = false
            })
        },
        fetchGroupList() {
            const postData = {
                group_id: null,
                shop_id: null,
            }
            this.$http.post(api.portal.zonenameListUrl, postData).then(resp => {
                this.form.group = resp.data
                this.formData.name = this.form.group[0].id;
                if (resp.data == []) {
                    this.formData.name = 0
                }
            })
            this.formData.date = this.getCurrentAndSevenDaysAgoDates()
        },
        changeGroup(groupid) {
            if (groupid == null || typeof (groupid) == 'undefined') {
                this.search.group_id = null
                this.search.shop_id = null
                this.form.shop = []
            } else {
                this.fetchShopList(groupid)
            }
        },
        fetchShopList(groupid) {
            const postData = {
                pagesize: 1000,
                pagenum: 1,
                groupID: groupid
            }
            this.$http.post(api.rbac.shopListUrl, postData).then(resp => {
                this.form.shop = resp.data.list
            }).finally(resp => {
                this.loading = false
            })
        },
        changeShop(shopid) {
            if (typeof shopid == "undefined")
                this.search.shop_id = null
        },
        getCurrentAndSevenDaysAgoDates() {
            const currentDate = new Date();
            const sevenDaysAgo = new Date(currentDate);
            sevenDaysAgo.setDate(currentDate.getDate() - 7);
            const formattedCurrentDate = currentDate.toISOString().slice(0, 10) + " 00:00:00";
            const formattedSevenDaysAgo = sevenDaysAgo.toISOString().slice(0, 10) + " 00:00:00";
            return [formattedSevenDaysAgo, formattedCurrentDate];
        },
        clearBtn() { 
            this.formData.name = 0
        },
    }
}
</script>

<style scoped>
.layout-search {
    padding: 0 25px;
    height: 60px;
    margin-bottom: 10px;
}

.layout-date {
    width: 245px;
    margin-right: 10px;
    color: #000;
}

.layout-input {
    width: 120px;
    margin-right: 10px;
}

.layout-select {
    width: 120px;
    margin-right: 10px;
}

#date .v-btn__content {
    color: #000;
}

.v-card__subtitle {
    padding-top: 0px;
}

.v-toolbar {
    margin-bottom: 0px;
}
</style>