<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ title }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                lazy-validation>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.bloc.table.name')" 
                                        v-model="data.name"
                                        :rules="rules.name"
                                        @change="data.name=formSpaces(data.name)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.bloc.table.contact')" 
                                        v-model="data.contact"
                                        :rules="rules.contact"
                                        @change="data.contact=formSpaces(data.contact)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field 
                                        :label="$vuetify.lang.t('$vuetify.userManage.bloc.table.domain')" 
                                        v-model="data.domain"
                                        :rules="rules.domain" 
                                        @change="data.domain=formSpaces(data.domain)"
                                        dense
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-select
                                        :items="radacctruleList"
                                        :label="$vuetify.lang.t('$vuetify.radacctRule.title')"
                                        item-text="name"
                                        item-value="id"
                                        v-model="data.radacct_rule"
                                        dense
                                        outlined
                                        clearable
                                    ></v-select>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleClose">
                            {{$vuetify.lang.t('$vuetify.userManage.close')}}
                        </v-btn>
                        <v-btn color="primary" @click="handleSave" :loading="loading">
                            {{$vuetify.lang.t('$vuetify.userManage.save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as api from '../../../api'
import { Notification } from 'element-ui'

export default {

    data () {
        return {

            title: this.$vuetify.lang.t('$vuetify.userManage.bloc.insertBtn'),

            dialog: false,

            loading: false,

            data: {
                name: null,
                contact: null,
                domain: null,
                radacct_rule: null,
            },

            rules: {
                name: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.name')
                ],
                contact: [
                    value =>  this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.contact'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.contact')
                ],
                domain: [
                    value => this.formSpaces(value)!='' || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.domain'),
                    value => (value !== null) || this.$vuetify.lang.t('$vuetify.userManage.bloc.rules.domain')
                ],
            },
            radacctruleList:[],
        }
    },

    methods: {
        
        formSpaces(str){
            if(str !== null && str !== undefined){
                let res = str.replace(/\s*/g,'')
                return res;
            }
        },
        
        handleOpen () {
            this.getruleList();
            this.dialog = true;
        },

        handleReset () {
            this.data = {
                name: null,
                contact: null,
                domain: null
            };
            this.$refs.form.resetValidation();
        },

        handleClose () {
            this.dialog = false;
            this.handleReset();
        },

        handleSave () {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$http.post(api.default.rbac.groupAddUrl, this.data).then(resp => {
                    // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                    if (!resp) return;
                    Notification({
                        type: 'success',
                        message: this.$vuetify.lang.t('$vuetify.userManage.success')
                    });
                    this.$emit('handleSearch');
                }).finally(resp => {
                    this.loading = false;
                    this.handleClose();
                })
            }
        },

        getruleList(){
            this.$http.post(api.default.radacct.radacctRuleList,{}).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.radacctruleList = resp.data.list;
            })
        },

    }
}
</script>

<style scoped>



</style>