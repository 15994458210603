<template>
  <div ref="charts" style="width:80%;height:300px;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  mounted() {
    this.drawLine();
  },

  methods: {
      transformData(inputData) { 
          const mapping = {
              fail: "认证失败",
              success: "认证成功",
              refail: "加载失败"
          };
          return Object.keys(inputData).map(key => {
              return {
                value: inputData[key],
                name: mapping[key]
              };
          });
      },
    setListData(listData) {
      this.drawLine(this.transformData(listData))
    },
    drawLine(data) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.charts);
      // 绘制图表
      const option = {
        title: {
          top: '6%',
          text: "认证成功率统计：",
          left: "left",
          textStyle: {
            fontSize: 16
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
          // position: [10, 10],
          textStyle: {
            fontSize: 16
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        legend: {
          top: "14%",
          left: "4%",

          orient: "vertical",// 竖直排列
          itemWidth: 10,  //长宽
          itemHeight: 8,
          "textStyle": {
            "fontSize": 14
          }
        },
        color: ['#6eb158', '#cdcdcd', '#3f8cff'],
        series: [
          {
            // name: "认证情况",
            type: "pie",
            radius: "55%",
            center: ['66%', '50%'],
            data:data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              formatter: "{b|{b}:}{c}",
              backgroundColor: "",
              borderColor: "",
              borderWidth: 1,
              borderRadius: 4,
              rich: {},
              "textStyle": {
                "fontSize": 12
              },
            },
            labelLine: {
              normal: {
                length: 6,
                length2: 5,
                lineStyle: {
                  width: 1,
                  color: "#000",
                }
              }
            }
          }
        ],
      };
      myChart.setOption(option)
    },
  },
}

</script>
