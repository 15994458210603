<template>
    <div class="statistics-layout">

        <portal-search @handleSearch="handleSearch" :title="title" :search="search" :types="tpl.portal_type_list_orig"
            @call-parent-method="CutBtn" search_type="user"></portal-search>
        <div v-show="!isShow">
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-card class="box-card">
                        <line-charts ref="linecharts"></line-charts>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card class="box-card">
                        <pie-charts ref="piecharts"></pie-charts>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="24">
                    <el-card class="box-card">
                        <BarCharts ref="barcharts" />
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div v-show="isShow">
            <el-row :gutter="15">
                <el-col :span="24">
                    <el-card>
                        <search-table ref="searchtable"></search-table>
                    </el-card>
                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script>
import api from '@/api.js'
import PortalSearch from './components/PortalSearch.vue'
import LineCharts from './components/LineCharts.vue'
import PieCharts from './components/PieCharts.vue'
import BarCharts from './components/BarCharts.vue'
import SearchTable from './components/SearchTable.vue'
export default {
    name: "test",
    components: {
        PortalSearch,
        LineCharts,
        PieCharts,
        BarCharts,
        SearchTable,
    },
    data() {
        return {
            tpl: {
                portal_type_list_orig: []
            },
            title: this.$vuetify.lang.t('$vuetify.portalManage.demo.title.user'),
            data: [],
            loading: true,
            total: 6,
            search: {
                pagesize: 8,
                pagenum: 1,
                name: null
            },
            FormData: {
                name: "",
                id: null,
                group_id: null,
                shop_id: null,
                portal_type: [],
            },
            isShow:false,
        }
    },
    methods: {
        handleSearch(formData) {
            let data = {
                gateway_id: null,
                time_range: [],
                client_mac: '',
            }
            if (formData) {
                data.gateway_id = formData.name
                data.time_range = formData.date
                data.client_mac = formData.client_mac
            }
            if (data.gateway_id == "" || data.time_range == null){
                this.$message({
                    message: '认证域和起止时间不能为空',
                    type: 'warning'
                });
                return
            }
            this.getLineData(data);
            this.getPieData(data);
            this.getBarData(data);
            this.getTableData(data);
        },
        getLineData(formdata) {
            this.loading = true;
            this.$http.post(api.authClockType, formdata).then(resp => {
                if (!resp) return;
                let authtype = []
                for (let i of resp.data) {
                    authtype.push(i.authtype)
                }
                let transformedData = this.LinetransformData(resp.data);
                this.$refs.linecharts.setListData(transformedData)
            }).finally(resp => {
                this.loading = false;
            })
        },
        getPieData(formdata) {
            this.loading = true;
            this.$http.post(api.authClockPr, formdata).then(resp => {
                if (!resp) return;
                this.$refs.piecharts.setListData(resp.data)
            }).finally(resp => {
                this.loading = false;
            })
        },
        getBarData(formdata) {
            this.loading = true;
            this.$http.post(api.authClockDay, formdata).then(resp => {
                if (!resp) return;
                let date = []
                for (let i of resp.data) {
                    date.push(i.date_day)
                }
                let transformedData = this.transformData(resp.data);
                this.$refs.barcharts.setListData(date, transformedData)
            }).finally(resp => {
                this.loading = false;
            })
        },
        // 表格数据
        getTableData(formdata) {
            // 异步
            setTimeout(() => {
                this.$refs.searchtable.InitData(formdata)
            }, 0);
        },
        // 保留两位小数并转换为百分比
        toPercentage(number) {
            if (typeof number !== 'number') {
                throw new Error('不是数字类型');
            }
            const percentage = (number).toFixed(2);
            // return percentage + '%';
            return percentage   ;
        },
        // 获取当前日期以及七天前的日期
        getCurrentAndSevenDaysAgoDates() {
            const currentDate = new Date();
            const sevenDaysAgo = new Date(currentDate);
            sevenDaysAgo.setDate(currentDate.getDate() - 7);
            const formattedCurrentDate = currentDate.toISOString().slice(0, 10) + " 00:00:00";
            const formattedSevenDaysAgo = sevenDaysAgo.toISOString().slice(0, 10) + " 00:00:00";
            return [formattedSevenDaysAgo, formattedCurrentDate];
        },
        // 转换格式
        transformData(data) { 
            return data.map(function (item) {
                return {
                    value: item.success_pr,
                    label: item.frequency
                };
            });
        },
        // 转换格式
        LinetransformData(data) {
            return data.map(function (item) {
                return {
                    authtype: item.authtype,
                    value: item.success_pr,
                    label: item.frequency
                };
            });
        },
        CutBtn(){
            this.isShow = !this.isShow
        },
    },
}
</script>

<style scoped>
.statistics-layout {
    margin: 0 10px;
}

.text {
    font-size: 14px;
}

.item {
    padding: 18px 0;
}

.box-card {
    width: 100%;
}

.el-row {
    margin-top: 20px;

    &:last-child {
        margin-left: 0px;
    }
}

.el-col {
    border-radius: 4px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
</style>