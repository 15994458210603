<template>
  <div ref="charts" style="width:80%;height:300px;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
    };
  },
  methods: {
    setListData(date, data) {
      this.drawLine(date, data)
    },
    drawLine(date, data) {
      const myChart = echarts.init(this.$refs.charts);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            var tooltipContent = '';
            params.forEach(function (item) {
              var dataIndex = item.dataIndex;
              var label = data[dataIndex].label;
              var value = (data[dataIndex].value * 100).toFixed(2) + '%';
              tooltipContent += `认证情况：${label}<br/>`;
              tooltipContent += `认证成功率：${value}<br/>`
            });
            return tooltipContent;
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '15%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        legend: {
          data: ['认证情况', '认证成功率']
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: date,
          }
        ],
        yAxis: [
          {
            type: 'value',
            max:1,
          }
        ],
        series: [
          {
            name: '认证成功率',
            type: 'line',
            barWidth: '13%',
            data: data.map(function (item) {
              return item.value;
            }),
            label: {
              show: true,
              formatter: function (params) {
                return params.value.toFixed(2);
              },
            },
          },
        ]
      };

      myChart.setOption(option)
    },
  },
}

</script>