<template>
  <div ref="charts" style="width:80%;height:300px;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  methods: {
    setListData(data) {
      this.drawLine(data)
    },
    drawLine(data) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.charts);
      // 绘制图表
      const option = {
        title: {
          text: '认证类型'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            var tooltipContent = '';
            params.forEach(function (item) {
              var dataIndex = item.dataIndex;
              var label = data[dataIndex].label;
              let value = (data[dataIndex].value * 100).toFixed(2) + '%';
              tooltipContent += `认证情况：${label}<br/>`;
              tooltipContent += `认证成功率：${value}<br/>`
            });
            return tooltipContent;
          }
        },
        legend: {
          data: ['认证情况', '认证成功率']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(function (item) {
            return item.authtype;
          }),
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '认证成功率',
            type: 'bar',
            barWidth: '13%',
            stack: 'Total',
            data: data.map(function (item) {
              return item.value;
            }),
            label: {
              show: true,
              formatter: function (params) {
                // 如果数据值为 0，则返回空字符串以隐藏标签
                return params.value === 0 ? '' : params.value.toFixed(2);
              }
            },
          }
        ]
      };
      myChart.setOption(option)
    },
  },
}

</script>
