<template>
    
    <div class="site-layout">

        <site-search
            @handleAdd="handleAdd"
            @handleSearch="handleSearch"
            :title ="title"
            :role  ="role"
            :group ="group"
            :place ="place"
            :search="search"
            :status="status"
        ></site-search>


        <site-data-table
            @handleSearch="handleSearch"
            :loading="loading"
            :columns="columns"
            :data   ="data"
            :total  ="total"
            :search ="search"
        >
            <template v-slot:action="action">
                <v-row justify="start">

                    <!-- 刷新密钥 -->
                    <v-icon color="primary" small @click="handleRefurbish(action.value.item)">loop</v-icon>
                    <span class="table-btn-span" @click="handleRefurbish(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.userManage.site.refurbishBtn') }}
                    </span>
                    <div class="mx-1"></div>

                    <!-- 编辑场所 -->
                    <v-icon color="primary" small @click="handleEidt(action.value.item)">edit</v-icon>
                    <span class="table-btn-span" @click="handleEidt(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.userManage.editBtn') }}
                    </span>
                    <div class="mx-1"></div>

                      <!-- 开启计费 -->
                    <v-icon color="primary" v-if="action.value.item.radacct_status == 2 || action.value.item.radacct_status == 0" small @click="handleRadacctRule(action.value.item,1)">money</v-icon>
                    <span class="table-btn-span" v-if="action.value.item.radacct_status==2 || action.value.item.radacct_status == 0" @click="handleRadacctRule(action.value.item,1)">
                         {{ $vuetify.lang.t('$vuetify.userManage.site.table.openRadacct') }}
                    </span>

                    <v-icon color="primary" v-if="action.value.item.radacct_status == 1" small @click="handleRadacctRule(action.value.item,2)">money</v-icon>
                    <span class="table-btn-span" v-if="action.value.item.radacct_status == 1" @click="handleRadacctRule(action.value.item,2)">
                        {{ $vuetify.lang.t('$vuetify.userManage.site.table.pauseRadacct') }}
                    </span>
                     <div class="mx-1"></div>


                    <v-icon color="primary" v-if="action.value.item.attachment" small @click="handleDownload(action.value.item)">mdi-download</v-icon>
                    <span class="table-btn-span" v-if="action.value.item.attachment" @click="handleDownload(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.userManage.site.downloadBtn') }}
                    </span>

                    <!-- 删除场所 -->
                    <v-icon color="primary" small @click="handleDelete(action.value.item)">delete</v-icon>
                    <span class="table-btn-span" @click="handleDelete(action.value.item)">
                        {{ $vuetify.lang.t('$vuetify.userManage.deleteBtn') }}
                    </span>

                </v-row>
            </template>
        </site-data-table>

        <add-site 
            ref="add"
            :role="role" 
            :group="group" 
            :place="place" 
            :rule="rules"
            @handleSearch="handleSearch"
        ></add-site>

        <edit-site 
            ref="edit"
            :role="role"
            :group="group"
            :place="place" 
            :rule="rules"
            @handleSearch="handleSearch"
        ></edit-site>

        <del-site ref="del" @handleSearch="handleSearch"></del-site>
        <refurbish-key-site ref="refurbish" @handleSearch="handleSearch"></refurbish-key-site>

        <update-radacct-status ref="update" 
            @handleSearch="handleSearch">
        </update-radacct-status>

        <appointment-date ref="appointment"
            @handleSearch="handleSearch">
        </appointment-date>
    </div>

</template>

<script>
import * as api from '../../api'
import axios from 'axios' 
import { Notification } from 'element-ui';

import SiteSearch from './components/SiteSearch.vue'
import SiteDataTable from './components/SiteDataTable.vue'
import AddSite from './components/AddSite.vue'
import EditSite from './components/EditSite.vue'
import DelSite from './components/DelSite.vue'
import AppointmentDate from './components/AppointmentDate.vue'
import UpdateRadacctStatus from './components/UpdateRadacctStatus.vue'
import RefurbishKeySite from './components/RefurbishKeySite.vue'

export default {

    components: {
        SiteSearch,
        SiteDataTable,
        AddSite,
        EditSite,
        DelSite,
        RefurbishKeySite,
        UpdateRadacctStatus,
        AppointmentDate,
    },

    data () {
      return {

            role: parseInt(window.sessionStorage.getItem('type')),

            title: this.$vuetify.lang.t('$vuetify.userManage.site.title'),

            group: [],
            place: [],
            status: [{ "name": "开启计费", "value": 1}, { "name": "暂停计费", "value": 2 }],
            rules:[],

            columns: [
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.site.table.name'),
                    sortable: false,
                    value: 'name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.site.table.contact'),
                    sortable: false,
                    value: 'contact'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.site.table.groupName'),
                    sortable: false,
                    value: 'group_name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.site.table.areaName'),
                    sortable: false,
                    value: 'area'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.site.table.key'),
                    sortable: false,
                    value: 'bindkey'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.userManage.site.table.remark'),
                    sortable: false,
                    value: 'remark'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.radacctRule.title'),
                    sortable: false,
                    value: 'rulename'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.userManage.site.table.status'),
                    sortable: false,
                    value: 'status'
                },
                {
                    text: '认证域',
                    sortable: false,
                    value: 'authzone_name'
                },
                {
                    text : this.$vuetify.lang.t('$vuetify.userManage.site.table.action'),
                    sortable: false,
                    value: 'action'
                }, 
            ],

            data: [],

            loading: false,
            total  : 0,

            search: {
                pagesize : 10,
                pagenum  : 1,
                provincecode: null,
                citycode : null,
                areacode : null,
                groupID  : null,
                name     : null,
                contact: null,
                // authzone_id: null,
                // authzone_name: null,
                radacct_status:0,
            },

            currentDate:null,          
      }
    },

    created () {
        this.initialize()
    },

    methods: {

        initialize() {
            this.getData();
            this.getPlace();
            this.getGroup();
            //this.getRules();
        },

        getPlace () {
            this.$http.post(api.default.system.placeListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.place = resp.data;
            })
        },

        getGroup () {
            this.$http.post(api.default.rbac.groupListUrl).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.group = resp.data;
            })
        },

        //请求规则下拉框使用
        // getRules(){
        //     var search = {
        //         pagesize: 8,
        //         pagenum: 1,
        //     }
        //     this.$http.post(api.default.radacct.radacctRuleList, search).then(resp => {
        //         // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
        //         if (!resp) return;
        //         this.rules = resp.data.list;
        //     })
        // },

        getData () {
            this.loading = true;
            if(this.search.radacct_status == null){
                this.search.radacct_status = 0
            }
           
            this.$http.post(api.default.rbac.shopListUrl, this.search).then(resp => {
                // 此处都是处理正确获取后台数据的情况， 错误的情况都已经在http内部处理
                if (!resp) return;
                this.total = parseInt(resp.data.total);
                if (resp.data.list == null){
                  this.data  = []
                }else{
                    this.data = resp.data.list;
                }
            }).finally(resp => {
                this.loading = false;
            })
        },

        handleAdd () {
            this.$refs.add.handleOpen();
        },

        handleEidt (item) {
            this.$refs.edit.handleOpen(item);
        },

        //启动或关闭计费
        handleRadacctRule(item,status){
            if(item.first_time!=""){
                //不是第一次设置
                this.$refs.update.handleOpen(item.id, status);
            }else{
                //选择第一次预约启动计费的时间
                this.$refs.appointment.handleOpen(item.id,status);
            }
        },

        handleDelete (item) {
            this.$refs.del.handleOpen(item);
        },

        handleSearch () {
            this.getData();
        },

        handleRefurbish (item) {
            this.$refs.refurbish.handleOpen(item);
        },

        //下载验收报告
        handleDownload(item){
            axios({
                method: 'GET',
                url: api.default.rbac.downloadReportUrl + "?id=" + item.id,
                responseType: 'blob'
            }).then(response => {
                if (response.status != 200 || !response.data) {
                    Notification({
                        type: 'warning',
                        message: "下载失败"
                    });
                    return
                }

                let filePath = item.attachment
                let arr = filePath.split('/')
                let fileName = arr[arr.length-1]

                let url = window.URL.createObjectURL(new Blob([response.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link); // 下载完成移除元素
                window.URL.revokeObjectURL(url); // 释放掉blob对象

            }).catch((error) => {
                Notification({
                    type: 'warning',
                    message: "下载失败"
                });
                return
            }).finally(resp => {})
        }
    }
}
</script>

<style scoped>

.site-layout {
    margin: 0 10px;
}

.table-btn-span {
    color: #1976d2;
    cursor: pointer;
}

</style>