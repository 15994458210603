<template>
    <div>
        <el-card class="box-card" style="margin-top:20px">
            <div class="body-table">
                <el-table ref="multipleTable" v-loading="loading" element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading" :data="tableData" stripe
                    header-row-class-name="table-header">
                    <el-table-column prop="device" label="设备品牌">
                    </el-table-column>
                    <el-table-column prop="authtype" label="认证方式">
                    </el-table-column>
                    <el-table-column prop="mac" label="mac">
                    </el-table-column>
                    <el-table-column label="状态">
                        <template #default="scope">
                            {{ PortalStatus(scope.row) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="创建时间">
                    </el-table-column>
                    <el-table-column prop="updated_at" label="更新时间">
                    </el-table-column>
                </el-table>
                <div class="body-bottom-page">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :page-sizes="[10, 20, 30, 50, 100]" layout="total, sizes, prev, pager, next, jumper"
                        :total="tableTotal">
                    </el-pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import api from '@/api.js'

export default {
    data() {
        return {
            tableTotal: 0,
            loading: false,
            tableData: [],
            postData : {
                client_mac: '',
                currentPage: 1,
                pageSize: 10,
                gateway_id: null,
                Time_range: [],
            },
        }
    },
    methods: {
        InitData(formdata) {
            this.postData.client_mac = formdata.client_mac
            this.postData.gateway_id = formdata.gateway_id
            this.postData.Time_range = formdata.time_range
            this.GetTable()
        },
        GetTable() {
            this.loading = true
            this.$http.post(api.authclockList, this.postData).then(resp => {
                this.tableData = []
                if (resp.errno == 0) {
                    for (let i of resp.data.list) {
                        this.tableData.push(i)
                    }
                    this.tableTotal = resp.data.total
                }
            }).finally(resp => {
                this.loading = false
            })
        },
        PortalStatus(row) {
            if (row.status == 0) {
                return '页面加载失败'
            } else if (row.status == 1) {
                return '认证失败'
            } else {
                return '成功'
            }
        },
        handleSizeChange(val) {
            this.postData.pageSize = val
            this.GetTable()
        },
        handleCurrentChange(val) {
            this.postData.currentPage = val
            this.GetTable()
        },
    }
}
</script>
<style scoped>
.el-input {
    width: 180px;
}

.body-table {
    background-color: rgba(255, 255, 255);
    text-align: center;

    .body-bottom-page {
        padding: 20px 0;
        display: inline-block;
    }
}
</style>
