<template>
	<div id="screen">
		<div class="header">
			<div class="bac">
				<p>{{ $vuetify.lang.t('$vuetify.bigScreen.title')}}</p>
			</div>
			<span class="select_button">
           </span>
		</div>
		<div class="submenu-left"></div>
		<div class="submenu-right"></div>
		<div class="container">
			<div class="container-inner">
				<div class="col-lg-2 fill-h" style="height: 100px;">
					<div class="s-wrapper s-wrapper-1">
						<div class="s-box-left">
							<p class="p_theme">{{ $vuetify.lang.t('$vuetify.bigScreen.left1')}}<span style="color:#fff;"></span></p>
							<div id="myChart3_1" :style="{width: '100%', height: '80%'}"></div>
						</div>
					</div>
					<div class="s-wrapper s-wrapper-1">
						<div class="s-box-left">
							<p class="p_theme">{{ $vuetify.lang.t('$vuetify.bigScreen.left2')}}<span style="color:#fff;"></span></p>
							<div id="myChart3_2" :style="{width: '100%', height: '70%'}"></div>
						</div>
					</div>
					<div class="s-wrapper s-wrapper-1">
						<div class="s-box-left">
							<p class="p_theme">{{ $vuetify.lang.t('$vuetify.bigScreen.left3')}}<span style="color:#fff;"></span></p>
							<div id="myChart3_4" :style="{width: '100%', height: '80%'}"></div>
						</div>
					</div>
					<div class="s-wrapper s-wrapper-1">
						<div class="s-box-left">
							<p class="p_theme">{{ $vuetify.lang.t('$vuetify.bigScreen.left4')}}<span style="color:#fff;"></span></p>
							<div id="myChart3_5" :style="{width: '100%', height: '80%'}"></div>
						</div>
					</div>
				</div>
				<div class="col-lg-7 fill-h">
					<div class="s-wrapper s-wrapper-10">
						<div class="warp">
							<div class="col-md-3 col-sm-6 col-xs-12">
								<div class="info-box">
									<div class="info-box-content">
										<span class="info-box-text"> {{ $vuetify.lang.t('$vuetify.bigScreen.firstTitle1')}} </span>
										<span class="info-box-number">
                                            {{firstValue1}}
                                        </span>
									</div>
								</div>
							</div>

							<div class="col-md-3 col-sm-4 col-xs-12">
								<div class="info-box">
									<div class="info-box-content">
										<span class="info-box-text"> {{ $vuetify.lang.t('$vuetify.bigScreen.firstTitle2')}} </span>
										<span class="info-box-number">
                                            {{firstValue2}}
                                        </span>
									</div>
								</div>
							</div>

							<div class="col-md-3 col-sm-4 col-xs-12">
								<div class="info-box">
									<div class="info-box-content">
										<span class="info-box-text"> {{ $vuetify.lang.t('$vuetify.bigScreen.firstTitle3') }} </span>
										<span class="info-box-number">
                                            {{firstValue3}}
                                        </span>
									</div>
								</div>
							</div>

							<div class="col-md-3 col-sm-4 col-xs-12">
								<div class="info-box">
									<div class="info-box-content">
										<span class="info-box-text"> {{ $vuetify.lang.t('$vuetify.bigScreen.firstTitle4') }} </span>
										<span class="info-box-number">
                                            {{firstValue4}}
                                        </span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="s-wrapper s-wrapper-7">
						<div class="s-map" id="s-map" :style="{width:'100%',height: '100%'}"></div>
					</div>

					<div class="s-wrapper s-wrapper-3">
						<div class="s-box-middle">
							<!-- <div class="col-lg-6" style="height:100%;" id="nowBuildingPeople"></div>
                            <div class="col-lg-6" style="height:100%;" id="historyMall"></div> -->
							<div class="col-lg-12" id="myChart2_1" :style="{height: '80%'}"></div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 fill-h">
					<div class="s-wrapper s-wrapper-3">
						<div class="s-box-left">
							<p class="charPieBarRight">{{ $vuetify.lang.t('$vuetify.bigScreen.right1')}}<span style="color:#fff;"></span></p>
							<div id="myChart4_1" :style="{width: '100%', height: '80%'}"></div>
						</div>
					</div>
					<div class="s-wrapper s-wrapper-4">
						<div class="s-box-left">
							<span class="charPieBarRight">{{ $vuetify.lang.t('$vuetify.bigScreen.right2')}}<span style="color:#fff;"></span></span>
							<div id="myChart4_2" :style="{width: '100%', height: '80%'}"></div>
						</div>
					</div>
					<div class="s-wrapper s-wrapper-3">
						<div class="s-box-left" style="padding:0 5px;">
							<span class="charPieBarRight">{{ $vuetify.lang.t('$vuetify.bigScreen.right3')}}<span style="color:#fff;"></span></span>
							<div id="myChart4_5" :style="{width: '100%', height: '80%'}"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { encryptData, decryptData } from './../../utils/encrypt.js';
	import * as systemConfig from './../../env.js'
	import * as api from '../../api'
	import Echarts from 'echarts'
	import CountTo from 'vue-count-to'

	import { china } from './../../../public/static/map/data-china.js'
	import { shanghai } from './../../../public/static/map/data-shanghai.js'
	import { hebei } from './../../../public/static/map/data-hebei.js'
	import { shangxi } from './../../../public/static/map/data-shangxi.js'
	import { neimenggu } from './../../../public/static/map/data-neimenggu.js'
	import { liaoning } from './../../../public/static/map/data-liaoning.js'
	import { jilin } from './../../../public/static/map/data-jilin.js'
	import { heilongjiang } from './../../../public/static/map/data-heilongjiang.js'
	import { jiangsu } from './../../../public/static/map/data-jiangsu.js'
	import { zhejiang } from './../../../public/static/map/data-zhejiang.js'
	import { anhui } from './../../../public/static/map/data-anhui.js'
	import { fujian } from './../../../public/static/map/data-fujian.js'
	import { jiangxi } from './../../../public/static/map/data-jiangxi.js'
	import { shangdong } from './../../../public/static/map/data-shangdong.js'
	import { henan } from './../../../public/static/map/data-henan.js'
	import { hubei } from './../../../public/static/map/data-hubei.js'
	import { hunan } from './../../../public/static/map/data-hunan.js'
	import { guangdong } from './../../../public/static/map/data-guangdong.js'
	import { guangxi } from './../../../public/static/map/data-guangxi.js'
	import { hainan } from './../../../public/static/map/data-hainan.js'
	import { sichuan } from './../../../public/static/map/data-sichuan.js'
	import { guizhou } from './../../../public/static/map/data-guizhou.js'
	import { yunnan } from './../../../public/static/map/data-yunnan.js'
	import { xizang } from './../../../public/static/map/data-xizang.js'
	import { shanxi } from './../../../public/static/map/data-shanxi.js'
	import { gansu } from './../../../public/static/map/data-gansu.js'
	import { qinghai } from './../../../public/static/map/data-qinghai.js'
	import { ningxia } from './../../../public/static/map/data-ningxia.js'
	import { xinjiang } from './../../../public/static/map/data-xinjiang.js'
	import { beijing } from './../../../public/static/map/data-beijing.js'
	import { tianjin } from './../../../public/static/map/data-tianjin.js'
	import { chongqing } from './../../../public/static/map/data-chongqing.js'
	import { xianggang } from './../../../public/static/map/data-xianggang.js'
	import { aomen } from './../../../public/static/map/data-aomen.js'

	import vueSeamless from 'vue-seamless-scroll'
	import Dynamic from './components/Dynamic.vue';

	export default {
		components: {
			Dynamic
		},
		data() {
			return {
				firstValue1: '',
				firstValue2: '',
				firstValue3: '',
				firstValue4: '',
				listData: [],
				todayTotal: 0,
				todayWifiTotal: 0,
				todayNewTotal: 0,
				historyTotal: 0,
				buildingData: [],
				childBuildingId: '',
				portalTotal: 0,
				timerDashboard: null,
				timerOther: null,
				tableDataChart: [],
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.index()
				this.drawChart2_all()
				this.drawChart3_all()
				this.drawChart4_all()
				this.fetchMapData()
				this.screenProvince()
				this.applicationflowTop();
			})
		},
		beforeDestroy() {
			clearInterval(this.timerDashboard);
			clearInterval(this.timerOther);
			this.timerDashboard = null;
			this.timerOther = null;
		},
		components: {
			vueSeamless
		},
		watch: {
			tableDataChart(data) {
				if(data.length>0){
					Echarts.extendsMap('s-map', {
						bgColor: '#154e90', // 画布背景色
						mapName: 'china', // 地图名
						text: 'map',
						goDown: true,
						callback: function(name, option, instance) {
							//console.log(name, option, instance);
						},
						// 数据展示             
						data: data,
					});
				}
			},
		},
		methods: {
			applicationflowTop(){
				this.$http.get(api.default.data.applicationflowTop).then(resp => {
					if(resp) {
						var top5=[],data5=[];
						var datas = resp.data;
						for(var i=0;i<5;i++){
							top5.push(datas[i].name);
							data5.push(parseInt(datas[i].flowcnt));
						}
						this.drawChart3_5(top5, data5)
					}
				}).finally(resp => {})
			},
			screenProvince() {
				this.$http.get(api.default.data.screenProvince).then(resp => {
					if(resp) {
						this.tableDataChart = resp.data;
					}
				}).finally(resp => {})
			},
			bytesToSize(bytes) {
				if(bytes === 0) return '0 B';
				var k = 1000, // or 1024
					sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
					i = Math.floor(Math.log(bytes) / Math.log(k));

				return(bytes / Math.pow(k, i)).toPrecision(3) + ' ' + (i>0?sizes[i]:'');
			},
			index() {
				this.$http.get(api.default.dashboard.index).then(resp => {
					if(resp) {

						this.firstValue1 = resp.data.gateway_on + '/' + resp.data.gateway_all
						this.firstValue2 = resp.data.tcpflowcnt + "/" + resp.data.udpflowcnt + "/" + resp.data.icmpflowcnt
						this.firstValue3 = resp.data.user + '/' + resp.data.user_max
						this.firstValue4 = "0/0"
					} else {
						this.firstValue1 = '/'
						this.firstValue2 = "/"
						this.firstValue3 = "/"
						this.firstValue4 = "/"
					}

				}).finally(resp => {
					this.loading = false
				})
			},
			timpstapChange(unixtimestamp) {
				var unixtimestamp = new Date(unixtimestamp);
				var year = 1900 + unixtimestamp.getYear();
				var month = "0" + (unixtimestamp.getMonth() + 1);
				var date = "0" + unixtimestamp.getDate();
				var hour = "0" + unixtimestamp.getHours();
				var minute = "0" + unixtimestamp.getMinutes();
				var second = "0" + unixtimestamp.getSeconds();
				return year + "-" + month.substring(month.length - 2, month.length) + "-" + date.substring(date.length - 2, date.length) +
					" " + hour.substring(hour.length - 2, hour.length) + ":" +
					minute.substring(minute.length - 2, minute.length) + ":" +
					second.substring(second.length - 2, second.length);
			},
			en_zh(en) {
				switch(en) {
					case 'unknown':
						return '未知流量'
						break;
					case 'general':
						return '常用协议'
						break;
					case 'p2p':
						return 'P2P下载'
						break;
					case 'nettv':
						return '网络电视'
						break;
					case 'im':
						return '即时通信'
						break;
					case 'stream':
						return '流媒体'
						break;
					case 'voip':
						return '网络电话'
						break;
					case 'game':
						return '网络游戏'
						break;
					case 'stock':
						return '股票交易'
						break;
					case 'database':
						return '数据库'
						break;
					case 'other':
						return '其他'
						break;
					case 'httpgroup':
						return 'HTTP协议'
						break;
					case 'mobile':
						return '移动应用'
						break;
					case 'custom':
						return '自定义协议'
						break;

					default:
						break;
				}
			},
			drawChart2_all() {
				this.$http.post(api.default.dashboard.conntrend).then(resp => {
					// legendData5 = resp.data.name
					if(!resp) return

					const rows = resp.data.rows

					let xAxis2_1 = []
					let legendData2_1 = []
					let series2_1 = []

					const legs = resp.data.rows[0]['data']
					for(let index = 0; index < legs.length; index++) {
						const time = this.timpstapChange(legs[index]['x'])
						xAxis2_1.push(time)
					}

					for(let index = 0; index < rows.length; index++) {
						const dataArr = []
						const name = this.en_zh(rows[index]['name'])
						rows[index]['data'].forEach(element => {
							dataArr.push(parseInt(element['y']))
						});
						series2_1.push({
							type: 'line',
							name: name,
							data: dataArr
						})
						legendData2_1.push(name)
					}
					this.drawChart2_1(legendData2_1, xAxis2_1, series2_1)

				}).finally(resp => {
					this.loading = false
				})
			},
			drawChart3_all() {

				let legendData1 = [];
				let data1 = []

				this.$http.get(api.default.dashboard.index).then(resp => {

					let legendData1 = ['在线', '离线']
					let data1 = []
					data1.push({
						value: resp.data.gateway_on,
						name: '在线'
					}, {
						value: resp.data.gateway_all - resp.data.gateway_on,
						name: '离线'
					})
					this.drawChart3_1(legendData1, data1)

					let data4 = []
					data4.push({
						value: resp.data.user,
						name: '在线'
					}, {
						value: resp.data.user_max - resp.data.user,
						name: '离线'
					})
					this.drawChart3_4(data4)

				}).finally(resp => {

				})

				this.$http.get(api.default.dashboard.dayauth).then(resp => {
					let legendData2 = ['认证用户']
					let xAxisData2 = []
					let data2 = []
					if(resp.data.length) {
						for(let index = 0; index < resp.data.length; index++) {
							data2.push(parseInt(resp.data[index]['count']))
							xAxisData2.push(resp.data[index]['date'])
						}
					}
					this.drawChart3_2(legendData2, xAxisData2, data2)

				}).finally(resp => {

                })
                
                this.$http.get(api.default.data.applicationflowTop).then(resp => {
                if(resp) {
                        let yAxis = []
                        let data = []
                        let flowcnt = resp.data.flowcnt

                        flowcnt.forEach(element => {
                            yAxis.unshift(element.name)
                            data.unshift(element.count)
                        });

                        this.drawChart3_5(yAxis,data)
                }

                }).finally(resp => {
                    
                })
			},
			drawChart4_all() {
				let legendData1 = ['在线', '离线']
				let data1 = [{
						value: 0,
						name: '在线'
					},
					{
						value: 0,
						name: '离线'
					},
				]
				this.drawChart4_1(legendData1, data1)

				this.$http.post(api.default.dashboard.upflow).then(resp => {
					// legendData5 = resp.data.name
					if(!resp) return

					const rows = resp.data.rows

					let xAxis4_2 = []
					let legendData4_2 = []
					let series4_2 = []

					const legs = resp.data.rows[0]['data']
					for(let index = 0; index < legs.length; index++) {
						const time = this.timpstapChange(legs[index]['x'])
						xAxis4_2.push(time)
					}

					for(let index = 0; index < rows.length; index++) {
						const dataArr = []
						const name = this.en_zh(rows[index]['name'])
						rows[index]['data'].forEach(element => {
							dataArr.push(parseInt(element['y']))
						});
						series4_2.push({
							type: 'line',
							name: name,
							data: dataArr
						})
						legendData4_2.push(name)
					}
					this.drawChart4_2(legendData4_2, xAxis4_2, series4_2)

				}).finally(resp => {
					this.loading = false
				})

				this.$http.post(api.default.dashboard.downflow).then(resp => {
					// legendData5 = resp.data.name
					if(!resp) return

					const rows = resp.data.rows

					let xAxis4_5 = []
					let legendData4_5 = []
					let series4_5 = []

					const legs = resp.data.rows[0]['data']
					for(let index = 0; index < legs.length; index++) {
						const time = this.timpstapChange(legs[index]['x'])
						xAxis4_5.push(time)
					}

					for(let index = 0; index < rows.length; index++) {
						const dataArr = []
						const name = this.en_zh(rows[index]['name'])
						rows[index]['data'].forEach(element => {
							dataArr.push(parseInt(element['y']))
						});
						series4_5.push({
							type: 'line',
							name: name,
							data: dataArr
						})
						legendData4_5.push(name)
					}
					this.drawChart4_5(legendData4_5, xAxis4_5, series4_5)

				}).finally(resp => {
					this.loading = false
				})

			},
			drawChart2_1(legendData, xAxis, series, dw) {
				let myChart = Echarts.init(document.getElementById('myChart2_1'));
				myChart.clear();
				myChart.setOption({
					title: {
						text: this.$vuetify.lang.t('$vuetify.bigScreen.chartTitle2_1'),
						left: '48%',
						top: '0%',
						textAlign: 'center',
						fontFamily: 'Microsoft YaHei',
						textStyle: {
							color: '#fff',
							fontSize: 14,
						}
					},
					background: 'rgba(0,0,0,0.1)',
					grid: {
						top: '10%',
						bottom: '50%',
						left: '6%',
						right: '4%'
					},
					tooltip: {
						trigger: 'axis',
						label: {
							show: true
						},

						formatter: function(params) {
							let res = ''
							let time = ''
							for(let index = 0; index < params.length; index++) {
								time = params[index].name
								res += params[index].seriesName + ":" + parseInt(params[index].data) + '<br/>';
							}
							return time + "<br/>" + res
						}
					},
					color: ['#7986CB', '#81C784', '#64B5F6', '#4DB6AC', '#4DD0E1', '#AED581', '#9575CD'],
					legend: {
						textStyle: {
							fontSize: 12,
							color: '#fff',
							fontFamily: 'Microsoft YaHei'
						},
						x: 'center',
						y: '75%',
						icon: "circle",
						formatter: function(name) {
							return name.length > 5 ? name.substr(0, 5) + "..." : name;
						},
						data: legendData
					},
					xAxis: {
						boundaryGap: true, //默认，坐标轴留白策略
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false,
							alignWithLabel: true
						},
						axisLabel: {
							margin: 10,
							color: '#e2e9ff',
							textStyle: {
								fontSize: 14
							},
						},
						data: xAxis
					},
					yAxis: {
						name: dw,
						axisLine: {
							show: false
						},
						splitLine: {
							show: false,
							lineStyle: {
								type: 'dashed',
								color: 'rgba(255, 255, 255, 0)'
							}
						},
						axisTick: {
							show: false
						},
						splitArea: {
							show: true,
							areaStyle: {
								color: 'rgba(255, 255, 255, 0)'
							}
						},
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: '#fff'
							},
							formatter: function(value, index) {
								return value;

							}
						}

					},

					series: series

				})

			},
			drawChart3_1(legendData, data) {
				this.chart = Echarts.init(document.getElementById('myChart3_1'));
				this.chart.clear();

				const optionData = {
					color: ['#81C784', '#778899'],
					tooltip: {
						trigger: 'item',
						formatter: '{b}: {c} ({d}%)'
					},
					grid: {
						left: '6%',
						right: '16%'
					},
					legend: {
						icon: "circle",
						textStyle: {
							fontSize: 12,
							color: '#fff',
							fontFamily: 'Microsoft YaHei'
						},
						bottom: 0,
						data: legendData
					},
					series: [{
						barMinWidth: 50,
						barMinHeight: 50,
						name: '',
						type: 'pie',
						radius: ['22%', '30%'],
						avoidLabelOverlap: false,
						label: {
							normal: {
								show: true,
								formatter: '{b} :'+'\n'+' {c} ({d}%)' 
							}
						},
						labelLine: {
							normal: {
								show: true
							}
						},
						data: data
					}]
				};

				this.chart.setOption(optionData);
			},
			drawChart3_2(legendData, xAxisData, data) {
				let myChart = Echarts.init(document.getElementById('myChart3_2'))

				myChart.setOption({
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						top: '25%',
						right: '13%',
						left: '15%',
						bottom: '18%'
					},
					xAxis: [{
						type: 'category',
						data: xAxisData,
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,0.12)'
							}
						},
						axisLabel: {
							margin: 10,
							color: '#e2e9ff',
							textStyle: {
								fontSize: 14
							},
						},
					}],
					yAxis: [{
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: '#fff'
							}
						},
						axisLine: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(255,255,255,0.12)'
							}
						},
						name: this.$vuetify.lang.t('$vuetify.common.people'),
						nameTextStyle: {
							color: "#fff",
							fontSize: 10,
							padding: [0, 0, 0, 0]
						}
					}],
					series: [{
						type: 'bar',
						data: data,
						barWidth: '10px',
						itemStyle: {
							normal: {
								color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: 'rgba(0,244,255,1)' // 0% 处的颜色
								}, {
									offset: 1,
									color: 'rgba(0,77,167,1)' // 100% 处的颜色
								}], false),
								barBorderRadius: [30, 30, 30, 30],
								shadowColor: 'rgba(0,160,221,1)',
								shadowBlur: 4,
							}
						},
						label: {
							normal: {
								show: true,
								lineHeight: 30,
								width: 80,
								height: 30,
								position: "top",
								distance: 1,
								formatter: [
									' {a|{c}}',
								].join(''),
								rich: {
									a: {
										color: '#fff',
										align: 'center',
									},
								}
							}
						}
					}]
				})
			},
			drawChart3_4(data) {
				this.chart = Echarts.init(document.getElementById('myChart3_4'));
				this.chart.clear();

				const optionData = {
					title: {
						text: '',
						top: '0%',
						textAlign: 'left',
						fontFamily: 'Microsoft YaHei',
						textStyle: {
							fontSize: 14,
						}
					},
					grid: {
						left: 55,
					},
					color: ['#cdcf9b', '#bc9e78', '#98c1ef', '#564e7d', '#5bd16b', '#e63995'],
					tooltip: {
						trigger: 'item',
						formatter: '{b}: {c} ({d}%)'
					},
					series: [{
						label: {
							normal: {
								show: true,
								formatter: '{b} :'+'\n'+' {c} ({d}%)' 
							}
						},
						barMaxWidth: 20,
						barMaxHeight: 20,
						type: 'pie',
						radius: ['23%', '35%'],
						avoidLabelOverlap: false,
						data: data
					}]
				};

				this.chart.setOption(optionData);
			},
			drawChart3_5(yAxis,data) {
				this.chart = Echarts.init(document.getElementById('myChart3_5'));
                this.chart.clear();
                
				const optionData = {
					tooltip: {
                        trigger: 'axis',
                        textStyle: {
                            align: 'left',
                            color: '#5cc1ff',
                            // color: '#fff',
                            fontSize: '16'
                        },
                        backgroundColor: 'rgba(15, 52, 135, 0.5)',
                        borderWidth: '1',
                        borderColor: '#5cc1ff',
                        extraCssText: 'box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);',
                        formatter: function(params) {
                            return params[0].name + "<br />" + "<span>" + params[0].value + "</span>";
                        }
                    },
                    label: {
                        normal: {
                            textStyle: {
                                color: "#cccccc"
                            }
                        },
                        emphasis: {
                            textStyle: {
                                color: "#cccccc"
                            }
                        }
                    },
                    grid: {//设置图表靠边位置
                        left: '10%',
                        right: '10%',
                        bottom: '10%',
                        top: '10%',
                        containLabel: true
                    },
                    yAxis: {
                        type: 'category',
                        axisLine: {
                            show:false,
                            lineStyle: {
                                color: '#8ac7ff'
                            }
                        },
                        axisTick: {
                            show: false,
                            interval: 0,
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            rotate: '0',
                            textStyle: {
                                fontSize: 10,
                                color: '#cee8ff'
                            }
                        },
                        data: yAxis,
                        splitLine: {
                            show: false//, "机构6", "机构7", "机构8", "机构9", "机构10"
                        }
                    },
                    xAxis: {
                        type: 'value',
                        name: '',
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ['rgba(138, 199, 255, .2)']
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        axisLine: {
                            show:false,
                            lineStyle: {
                                color: '#01c2db'
                            }
                        }
                    },
                    series: [{
                        name: '数量',
                        type: 'bar',
                        stack: '总量',
                        barWidth: 10,
                        label: {
                            normal: {
                                show: true,
                                color:'#fff',
                                position: 'right',
                                formatter: "{c}"
                            }
                        },
                        itemStyle: {
                            normal: {
                                barBorderWidth: '0',
                                barBorderRadius: [10, 10, 10, 10],
                                barBorderColor: 'rgb(0,255,132)',
                                color: new Echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: 'rgba(0,244,255,.4)'// rgba(255,255,132, .4)，rgba(0,0,255, .4)
                                }, {
                                    offset: 1,
                                    color: 'rgb(0,244,255)' //#0000F，F#ffbd19
                                }])
                            },
                            emphasis: {
                                barBorderWidth: '1',
                                barBorderColor: 'rgb(0,255,132)'
                                // color: 'rgba(26,177,98,.8)'
                            }
                        },
                        // 顺序 从下向上 传入
                        data: data//,1250, 1278,1600,   1820,2335
                    }]
				}
				this.chart.setOption(optionData);
			},
			drawChart4_1(legendData, data) {
				this.chart = Echarts.init(document.getElementById('myChart4_1'));
				this.chart.clear();

				const optionData = {
					color: ['#81C784', '#778899'],
					tooltip: {
						trigger: 'item',
						formatter: '{b}: {c} ({d}%)'
					},
					legend: {
						icon: "circle",
						textStyle: {
							fontSize: 12,
							color: '#fff',
							fontFamily: 'Microsoft YaHei'
						},
						bottom: 0,
						data: legendData
					},
					series: [{
						barMinWidth: 50,
						barMinHeight: 50,
						name: '',
						type: 'pie',
						radius: ['32%', '45%'],
						label: {
							normal: {
								show: true,
								formatter: '{b} :'+'\n'+' {c} ({d}%)' ,
							},
						},
						labelLine: {
							normal: {
								show: true
							}
						},
						data: data
					}]
				};

				this.chart.setOption(optionData);
			},
			drawChart4_2(legendData, xAxis, series, dw) {

				let myChart = Echarts.init(document.getElementById('myChart4_2'))

				function bytesToSize(bytes) {
					if(bytes === 0) return '0 B';
					var k = 1000, // or 1024
						sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
						i = Math.floor(Math.log(bytes) / Math.log(k));

					return(bytes / Math.pow(k, i)).toPrecision(3) + ' ' + (i>0?sizes[i]:'');
				}

				myChart.setOption({

					background: 'rgba(0,0,0,0.1)',
					grid: {
						top: '40%',
						bottom: '19%',
						left: '16%',
						right: '4%'
					},
					tooltip: {
						trigger: 'axis',
						label: {
							show: true
						},

						formatter: function(params) {
							let res = ''
							let time = ''
							for(let index = 0; index < params.length; index++) {
								time = params[index].name
								res += params[index].seriesName + ":" + bytesToSize(parseInt(params[index].data)) + '<br/>';
							}
							return time + "<br/>" + res
						}
					},
					color: ['#7986CB', '#81C784', '#64B5F6', '#4DB6AC', '#4DD0E1', '#AED581', '#9575CD'],
					legend: {
						textStyle: {
							fontSize: 12,
							color: '#fff',
							fontFamily: 'Microsoft YaHei'
						},
						x: 'center',
						y: '5px',
						icon: "circle",
						formatter: function(name) {　　　　
							return name.length > 5 ? name.substr(0, 5) + "..." : name;　　
						},
						data: legendData
					},
					xAxis: {
						boundaryGap: true, //默认，坐标轴留白策略
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false,
							alignWithLabel: true
						},
						axisLabel: {
							margin: 10,
							color: '#e2e9ff',
							textStyle: {
								fontSize: 14
							},
						},
						data: xAxis
					},
					yAxis: {
						name: dw,
						axisLine: {
							show: false
						},
						splitLine: {
							show: false,
							lineStyle: {
								type: 'dashed',
								color: 'rgba(255, 255, 255, 0)'
							}
						},
						axisTick: {
							show: false
						},
						splitArea: {
							show: true,
							areaStyle: {
								color: 'rgba(255, 255, 255, 0)'
							}
						},
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: '#fff'
							},
							formatter: function(value, index) {
								return bytesToSize(value);

							}
						}

					},

					series: series

				})
			},
			drawChart4_5(legendData, xAxis, series, dw) {
				let myChart = Echarts.init(document.getElementById('myChart4_5'));

				function bytesToSize(bytes) {
					if(bytes === 0) return '0 B';
					var k = 1000, // or 1024
						sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
						i = Math.floor(Math.log(bytes) / Math.log(k));

					return(bytes / Math.pow(k, i)).toPrecision(3) + ' ' + (i>0?sizes[i]:'');
				}
				myChart.setOption({
					background: 'rgba(0,0,0,0.1)',
					grid: {
						top: '40%',
						bottom: '19%',
						left: '16%',
						right: '4%'
					},
					tooltip: {
						trigger: 'axis',
						label: {
							show: true
						},

						formatter: function(params) {
							let res = ''
							let time = ''
							for(let index = 0; index < params.length; index++) {
								time = params[index].name
								res += params[index].seriesName + ":" + bytesToSize(parseInt(params[index].data)) + '<br/>';
							}
							return time + "<br/>" + res
						}
					},
					color: ['#7986CB', '#81C784', '#64B5F6', '#4DB6AC', '#4DD0E1', '#AED581', '#9575CD'],
					legend: {
						textStyle: {
							fontSize: 12,
							color: '#fff',
							fontFamily: 'Microsoft YaHei'
						},
						x: 'center',
						y: '5px',
						icon: "circle",
						formatter: function(name) {　　　　
							return name.length > 5 ? name.substr(0, 5) + "..." : name;　　
						},
						data: legendData
					},
					xAxis: {
						boundaryGap: true, //默认，坐标轴留白策略
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false,
							alignWithLabel: true
						},
						axisLabel: {
							margin: 10,
							color: '#e2e9ff',
							textStyle: {
								fontSize: 14
							},
						},
						data: xAxis
					},
					yAxis: {
						name: dw,
						axisLine: {
							show: false
						},
						splitLine: {
							show: false,
							lineStyle: {
								type: 'dashed',
								color: 'rgba(255, 255, 255, 0)'
							}
						},
						axisTick: {
							show: false
						},
						splitArea: {
							show: true,
							areaStyle: {
								color: 'rgba(255, 255, 255, 0)'
							}
						},
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: '#fff'
							},
							formatter: function(value, index) {
								return bytesToSize(value);
							}
						}
					},
					series: series
				})
			},
			fetchMapData() {
				Echarts.registerMap('china', china);
				Echarts.extendsMap = function(id, opt) {
					var chart = this.init(document.getElementById(id));
					var curGeoJson = {};
					var geoCoordMap = {
						'新疆': [86.61, 40.79],
						'西藏': [89.13, 30.66],
						'黑龙江': [128.34, 47.05],
						'吉林': [126.32, 43.38],
						'辽宁': [123.42, 41.29],
						'内蒙古': [112.17, 42.81],
						'北京': [116.40, 40.40],
						'宁夏': [106.27, 36.76],
						'山西': [111.95, 37.65],
						'河北': [115.21, 38.44],
						'天津': [117.04, 39.52],
						'青海': [97.07, 35.62],
						'甘肃': [103.82, 36.05],
						'山东': [118.01, 36.37],
						'陕西': [108.94, 34.46],
						'河南': [113.46, 34.25],
						'安徽': [117.28, 31.86],
						'江苏': [120.26, 32.54],
						'上海': [121.46, 31.28],
						'四川': [103.36, 30.65],
						'湖北': [112.29, 30.98],
						'浙江': [120.15, 29.28],
						'重庆': [107.51, 29.63],
						'湖南': [112.08, 27.79],
						'江西': [115.89, 27.97],
						'贵州': [106.91, 26.67],
						'福建': [118.31, 26.07],
						'云南': [101.71, 24.84],
						'台湾': [121.01, 23.54],
						'广西': [108.67, 23.68],
						'广东': [113.98, 22.82],
						'海南': [110.03, 19.33],
						'澳门': [113.54, 22.19],
						'香港': [114.17, 22.32],
					};
					var levelColorMap = {
						'1': 'rgba(241, 109, 115, .8)',
						'2': 'rgba(255, 235, 59, .7)',
						'3': 'rgba(147, 235, 248, 1)'
					};
					var defaultOpt = {
						mapName: 'china', // 地图展示
						goDown: false, // 是否下钻
						activeArea: [], // 区域高亮,同echarts配置项
						data: [],
						// 下钻回调(点击的地图名、实例对象option、实例对象)
						callback: function(name, option, instance) {}
					};
					if(opt) opt = this.util.extend(defaultOpt, opt);
					// 层级索引
					var name = [opt.mapName];
					var idx = 0;
					var pos = {
						leftPlus: 115,
						leftCur: 150,
						left: 198,
						top: 50
					};
					var line = [
						[0, 0],
						[8, 11],
						[0, 22]
					];
					var style = {
						font: '18px "Microsoft YaHei", sans-serif',
						textColor: '#eee',
						lineColor: 'rgba(147, 235, 248, .8)'
					};
					var handleEvents = {
						/**
						 * name 地图名
						 **/
						createBreadcrumb: function(name) {
							var cityToPinyin = {
								'上海': 'shanghai',
								'河北': 'hebei',
								'山西': 'shangxi',
								'内蒙古': 'neimenggu',
								'辽宁': 'liaoning',
								'吉林': 'jilin',
								'黑龙江': 'heilongjiang',
								'江苏': 'jiangsu',
								'浙江': 'zhejiang',
								'安徽': 'anhui',
								'福建': 'fujian',
								'江西': 'jiangxi',
								'山东': 'shangdong',
								'河南': 'henan',
								'湖北': 'hubei',
								'湖南': 'hunan',
								'广东': 'guangdong',
								'广西': 'guangxi',
								'海南': 'hainan',
								'四川': 'sichuan',
								'贵州': 'guizhou',
								'云南': 'yunnan',
								'西藏': 'xizang',
								'陕西': 'shanxi',
								'甘肃': 'gansu',
								'青海': 'qinghai',
								'宁夏': 'ningxia',
								'新疆': 'xinjiang',
								'北京': 'beijing',
								'天津': 'tianjin',
								'重庆': 'chongqing',
								'香港': 'xianggang',
								'澳门': 'aomen'
							};
						},
						initSeriesData: function(data) {
							var temp = [];
							for(var i = 0; i < data.length; i++) {
								var geoCoord = geoCoordMap[data[i].provinceName.substring(0,2)];
								if(geoCoord) {
									temp.push({
										name: data[i].provinceName.substring(0,2),
										value: geoCoord,
										crew: data[i].n,
									});
								}
							};
							return temp;
						}
					};

					var option = {
						grid: {
							left: '1%',
							right: '1%',
							bottom: '1%',
							top: '1%',
							containLabel: true
						},
						tooltip: {
							show: true,
							trigger: 'item',
							alwaysShowContent: false,
							backgroundColor: 'rgba(50,50,50,0.7)',
							hideDelay: 100,
							triggerOn: 'mousemove',
							enterable: true,
							position: ['60%', '70%'],
							formatter: function(params, ticket, callback) {
								return '简称：' + params.data.name + '<br/>' + '设备数：' + params.data.crew + '台' + '<br/>'
							}
						},
						geo: {
							map: opt.mapName,
							roam: true,
							zoom: 1,
							label: {
								normal: {
									show: true,
									textStyle: {
										color: '#fff'
									}
								},
								emphasis: {
									textStyle: {
										color: '#fff'
									}
								}
							},
							itemStyle: {
								normal: {
									borderColor: 'rgba(147, 235, 248, 1)',
									borderWidth: 1,
									areaColor: {
										type: 'radial',
										x: 0.5,
										y: 0.5,
										r: 0.8,
										colorStops: [{
											offset: 0,
											color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
										}, {
											offset: 1,
											color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
										}],
										globalCoord: false // 缺省为 false
									},
									shadowColor: 'rgba(128, 217, 248, 1)',
									// shadowColor: 'rgba(255, 255, 255, 1)',
									shadowOffsetX: -2,
									shadowOffsetY: 2,
									shadowBlur: 10
								},
								emphasis: {
									areaColor: '#389BB7',
									borderWidth: 0
								}
							},
						},
						series: [{
							type: 'effectScatter',
							coordinateSystem: 'geo',
							showEffectOn: 'render',
							rippleEffect: {
								period: 15,
								scale: 4,
								brushType: 'fill'
							},
							hoverAnimation: true,
							itemStyle: {
								normal: {
									color: 'yellow',
									shadowBlur: 10,
									shadowColor: '#333'
								}
							},
							data: handleEvents.initSeriesData(opt.data)
						}]
					};
					chart.setOption(option);
					return chart;
				};
				Echarts.extendsMap('s-map', {
					bgColor: '#154e90', // 画布背景色
					mapName: 'china', // 地图名
					text: 'map',
					goDown: true,
					callback: function(name, option, instance) {
						//console.log(name, option, instance);
					},
					// 数据展示             
					data: [],
				});
			},
			goOtherData() {
				this.$router.push({
					path: '/screen_data'
				})
			}
		}
	}
</script>
<style>
	#screen .el-input__inner {
		background: none;
		color: rgb(60, 155, 214);
	}
</style>
<style scoped="scoped">
	.info-box-number {
		display: block;
		font-weight: bold;
		font-size: 18px;
		text-align: center;
		color: #fff;
		margin-top: -15px;
	}
	
	.count_white {
		color: #fff;
	}
	
	.number {
		text-align: center;
		color: #fff;
	}
	
	.info-box-content {
		text-align: center;
		margin-left: 0;
	}
	
	.info-box-text {
		color: #65c4f1;
		font-size: 15px;
	}
	
	.info-box {
		line-height: 2.5;
		background: url('../../../public/static/screen/border1.png') no-repeat;
		background-size: 100% 100%;
	}
	
	#stayTimeFlow,
	#hourlyStayTimeFlow {
		width: 100%;
		height: calc(100%-35px);
		overflow: hidden;
	}
	
	#li5 {
		width: 100%;
		height: calc(100%-45px);
		overflow: hidden;
	}
	
	.container {
		max-width: 3000px;
	}
	
	.charPieBarRight {
		display: block;
		padding-left: 10px;
		height: 35px;
		line-height: 35px;
		color: #65c4f1;
		font-size: 15px;
	}
	
	.warp {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}
	/* .warp .tr25{
        width: calc(100%/3)!important;
    }*/
	
	.warp .tr {
		width: 100%;
		border-radius: 5px;
		margin-top: 4px;
		overflow: hidden;
		background: rgb(39, 59, 83);
	}
	
	.warp .td {
		width: 20%;
		height: 20px;
		float: left;
		line-height: 20px;
		font-size: 15px;
		text-align: left;
		padding-left: 10px;
		color: rgb(153, 209, 226);
	}
	
	#newOldPie,
	#floorFlow,
	#areaFlow,
	#timeFlow {
		width: 100%;
		height: calc(100%-35px);
	}
	
	.p_theme {
		height: 35px;
		line-height: 35px;
		margin: 0;
		font-size: 15px;
		width: 100%;
		border-bottom: 1px solid rgb(30, 80, 117);
		color: #65c4f1;
	}
	
	.info-box-text {
		width: 100%;
		margin-top: 10px;
		text-align: center;
	}
	
	.ul-box {
		padding: 8px 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row-reverse;
	}
	
	.ul-box li {
		flex: 1;
		width: 100%;
		border-right: 0.5px solid rgb(30, 80, 117);
		height: 100%;
		padding: 0;
		list-style: none;
	}
	
	.col-lg-2,
	.col-lg-3,
	.col-lg-7 {
		float: left;
		padding-left: 4px;
		padding-right: 4px;
	}
	
	.s-box-middle {
		height: 100%;
		min-height: 170px;
		background-size: 100% 100%;
		box-sizing: border-box;
		background: url('../../../public/static/screen/border4.png') no-repeat;
		background-size: 100% 100%
	}
	
	.s-box-left {
		height: 100%;
		min-height: 170px;
		background-size: 100% 100%;
		box-sizing: border-box;
		padding: 0 10px;
		box-shadow: 0 0 3rem rgba(100, 200, 255, .8) inset;
		background: rgba(0, 0, 0, .3);
	}
	
	.s-wrapper {
		padding-bottom: 5px;
		box-sizing: border-box;
	}
	
	.s-wrapper-10 {
		height: 100px;
	}
	
	.s-wrapper-0 {
		height: 20%;
	}
	
	.s-wrapper-1 {
		height: 25%;
	}
	
	.s-wrapper-2 {
		height: 60%;
	}
	
	.s-wrapper-3 {
		height: 30%;
	}
	
	.s-wrapper-4 {
		height: 40%;
	}
	
	.s-wrapper-5 {
		height: 50%;
	}
	
	.s-wrapper-7 {
		/* height: calc(70%-100px); */
		height: calc(70% - 100px);
	}
	
	.s-wrapper-8 {
		height: 80%;
	}
	
	.s-map {
		width: 100%;
		height: 100%;
	}
	
	.container-inner {
		width: 100%;
		height: 100%;
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.fill-h {
		height: 100%;
		min-height: 100%;
	}
	
	.row {
		margin-left: -5px;
		margin-right: -5px;
	}
	
	.submenu-left {
		background: url('../../../public/static/screen/submenu-left.png') 0 50% no-repeat;
		height: 564px;
		position: absolute;
		left: 0;
		top: 10%;
		min-width: 18px;
	}
	
	.submenu-right {
		background: url('../../../public/static/screen/submenu-right.png') 0 50% no-repeat;
		height: 564px;
		position: absolute;
		right: 0;
		bottom: 10%;
		min-width: 18px;
	}
	
	.container {
		width: 100%;
		height: calc(100% - 80px);
		min-height: 555px;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		position: relative;
	}
	
	#screen {
		width: 100%;
		height: 100%;
		background: url('./../../../public/static/screen/bac3.png')no-repeat;
		background-size: 100% 100%;
		position: relative;
	}
	
	.col-md-3 {
		float: left;
	}
	
	.header .time {
		width: 200px;
		font-size: 18px;
		text-align: right;
		line-height: 35px;
		padding-right: 6px;
		height: 35px;
		display: block;
		position: absolute;
		right: 5px;
		top: 40px;
		background: url('../../../public/static/screen/time.png') no-repeat;
		background-size: 100% 100%;
		color: rgb(65, 169, 232);
	}
	
	.header {
		width: 100%;
		height: 80px;
		overflow: hidden;
		position: relative;
	}
	
	.header .select_button {
		width: 170px;
		height: 35px;
		display: block;
		position: absolute;
		left: 170px;
		top: 42px;
		color: rgb(60, 155, 214);
	}
	
	.header .look_button {
		text-align: center;
		line-height: 35px;
		cursor: pointer;
		width: 120px;
		height: 35px;
		display: block;
		position: absolute;
		left: 20px;
		top: 40px;
		background: url('../../../public/static/screen/dashboard.png') no-repeat;
		background-size: 100% 100%;
		color: rgb(60, 155, 214);
	}
	
	.header .bac {
		text-align: center;
		width: 100%;
		line-height: 50px;
		font-size: 2.8rem!important;
		margin-top: 8px;
		height: 60px;
		background: url('../../../public/static/screen/header.png') center no-repeat;
	}
	
	.header .bac p {
		background: -webkit-linear-gradient(top, #fff, #65c4f1);
		-webkit-text-fill-color: transparent;
	}
</style>